/*
 * Progress Bar
 */
.mi-skills-area {
  .container {
    background-color: #fff;
    border-radius: 15px;
    padding: 5vh 10vw;
    color: #000;
  }


  @media #{$layout-mobile-sm} {
    padding: 0vh 5vw;


  }
}

.mi-progress {
  padding: 1vw 1vh 1vw 1vh;



  &-title {
    font-weight: 600;
    margin-bottom: 0;
  }

  &-inner {
    display: flex;
    align-items: center;
  }

  &-percentage {
    flex: 0 0 60px;
  }

  &-container {
    background: $color-border;
    display: block;
    height: 6px;
    width: 100%;
    position: relative;
  }

  &-active {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: $color-theme;
    width: 0;
    transition: all 1s cubic-bezier(0.01, 0.57, 0.68, 1.05) 0s;
  }
}