/*
 * Home Styles
 */

.wave {
  top: 90vh;
  z-index: -10;
  left: 0px;
  width: 100%;
  position: absolute;
  opacity: 50%;
  transition: opacity ease-out 0.6s;
}

.mi-home {
  &-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    min-height: 100vh;
    position: relative;
    z-index: 1;
    background-color: $color-secondary;
    /* For browsers that do not support gradients */
    //background-image: linear-gradient(#a6bdbd, white);
    //clip-path: inset(5vh 5vw 5vh 5vw round 15px);
  }

  &-particle {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &-content {
    text-align: center;

    h1 {
      font-weight: 700;
      color: $light-color-bg-body;
      font-size: 3rem;
    }

    h2 {
      font-weight: 600;
      color: $light-color-bg-body;
      font-size: 2.5rem;
      line-height: 6rem;
    }

    h3 {
      font-weight: 500;
      color: $light-color-bg-body;
      font-size: 2rem;
    }

    p {
      margin-top: 15px;
      font-size: 2rem;
      line-height: 4rem;
    }

    .name {
      strong {
        font-weight: 900;
        color: #daa520;
      }
    }

    .job {
      padding-top: 2vh;

      span {
        background-color: #a0750b;
        padding: 1vh 1vw;
        border-radius: 20px;

      }

    }

    .mi-socialicons {
      margin-top: 20px;
    }
  }

  @media #{$layout-laptop} {}

  @media #{$layout-notebook} {}

  @media #{$layout-tablet} {
    &-content {

      .job {
        padding-bottom: 5vh;

        span {
          border-radius: 15px;
          padding-left: 2vw;
          padding-right: 2vw;
        }
      }

      p {
        font-size: 1.15rem;
        line-height: 1.75rem;
      }
    }
  }

  @media #{$layout-mobile-lg} {
    &-content {

      .job {
        padding-bottom: 5vh;

        span {
          border-radius: 12px;
          padding-left: 2vw;
          padding-right: 2vw;
        }
      }

      p {
        font-size: 1.08rem;
        line-height: 1.55rem;
      }
    }
  }

  @media #{$layout-mobile-sm} {
    &-content {

      text-align: left;
      padding-left: 5vw;
      padding-right: 5vw;

      h1 {
        font-size: 2rem;
        line-height: 2.5rem;

        span {
          display: block;
        }
      }

      h2 {
        font-size: 1.5rem;
        line-height: 3rem;
      }

      h3 {
        font-size: 1.8rem;
        line-height: 3rem;
      }

      .job {
        padding-bottom: 5vh;

        span {
          border-radius: 10px;
          padding-left: 2vw;
          padding-right: 2vw;
        }
      }
    }
  }
}



.topmenu {
  position: absolute;
  width: 100vw;
  top: 2vh;
  right: 5vw;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
}

.social-menu img {
  max-height: 30px;
}

.social-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.resume_button {
  color: #fff;
  // border-radius: 8px;
  padding-top: 0.2vh;
  padding-bottom: 0.2vh;
  font-weight: 600;
}

.social-menu a {
  padding: 2vh;
  text-decoration: none;
  color: #fff;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  color: white;
  box-shadow: 0 0 30px rgba(176, 113, 140, 0.35);
  background-color: $color-theme;

  .button {
    background-color: $color-theme;
    font-size: 1rem;
    cursor: pointer;

    a {
      text-decoration: unset;
      color: unset;
    }
  }

  .button:hover {
    font-weight: bold;
  }

  .last {
    border-radius: 0 20px 20px 0;
    border-left: 1px solid #fff;
  }

  .first {
    border-radius: 20px 0px 0px 20px;
    border-right: 1px solid #fff;
  }

  .label {
    font-size: 1rem;
  }
}

.menu__bg {
  position: relative;
  padding: 1.5vh 3vw;
}



.bottommenu {
  position: fixed;
  width: 100vw;
  bottom: 2vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
}