.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

    .modalContent {
        color: $light-color-body;
    }

    h6 {
        font-weight: bold;
    }

    h3 {
        text-align: center;
        font-size: 1.6rem;
    }

    .col-md-6 {
        float: left
    }
}


.app_tour {

    @media #{$layout-mobile-sm} {
        padding-left: 0;
        padding-right: 0;
    }

    @media #{$layout-mobile-lg} {
        padding-left: 0;
        padding-right: 0;
    }


}

.app_tour_section {
    border-top: 1px solid #eee;
    padding-top: 3vh;
    padding-bottom: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media #{$layout-mobile-lg} {
        display: block;
    }
}

.app_tour_gif img {
    width: 100%;
    min-height: 50vh;
    max-height: 70vh;
    object-fit: contain;
}

.historical_data_gif {
    text-align: center;
    justify-content: center;
    display: flex;

    img {
        max-width: 400px;
        width: 100%;
    }
}

.historical_data_text {
    padding-left: 2vw;
    padding-right: 2vw;
}

.app_tour_title {
    color: $light-color-secondary;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5em;

    @media #{$layout-mobile-sm} {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 2em;
    }

    @media #{$layout-mobile-lg} {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 2em;
    }

    @media #{$layout-tablet} {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 2em;
    }
}

.website-talk {
    padding-top: 5vh;

    @media #{$layout-mobile-sm} {
        padding-top: 0;
    }

    @media #{$layout-mobile-lg} {
        padding-top: 0;
    }

    @media #{$layout-tablet} {
        padding-top: 0;
    }
}


.app_tour_text {
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 5vh;
}

.websiteButton {
    background-color: $light-color-secondary !important;
    color: #fff !important;
    padding-left: 5vw;
    padding-right: 5vw;
    float: none !important;
    margin-top: 2vh;
    margin-bottom: 2vh;
    border-radius: 20px;
    padding-bottom: 1vh;
    height: unset;
    padding-top: 1vh;

    a {
        color: unset;
    }
}

.bselogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    img {

        max-width: 900px;

        @media #{$layout-mobile-sm} {
            max-width: 100%;
        }

        @media #{$layout-mobile-lg} {
            max-width: 100%;
        }

        @media #{$layout-tablet} {
            max-width: 100%;
        }

    }
}



.modal-main {

    position: fixed;
    background: #fff;
    width: 90vw;
    height: auto;
    border-radius: 15px;
    padding: 5vh 5vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;
    max-height: 90vh;

    @media #{$layout-mobile-sm} {
        padding: 2vh 10vw;
    }

    button {
        color: $light-color-secondary;
        float: right;
        border: none;
        background: none;

    }

    ul {
        margin-bottom: 0;
    }

    h2 {
        color: $light-color-heading;
        padding-bottom: 2vh;
        padding-top: 2vh;

        @media #{$layout-mobile-sm} {
            font-size: 1.5rem;
            line-height: 3rem;
            font-weight: 600;
            padding-bottom: 0;
            padding-top: 2vh;
        }

        @media #{$layout-mobile-lg} {
            font-size: 1.5rem;
            line-height: 3rem;
            font-weight: 600;
            padding-bottom: 0;
            padding-top: 2vh;
        }

        @media #{$layout-tablet} {
            font-size: 1.5rem;
            line-height: 3rem;
            font-weight: 600;
            padding-bottom: 0;
            padding-top: 2vh;
        }
    }

    h1 {
        color: $light-color-border;
        font-size: 2.5rem;
        line-height: 4rem;

        @media #{$layout-mobile-sm} {
            font-size: 1.5rem;
            line-height: 3rem;
            font-weight: 600;
        }

        @media #{$layout-mobile-lg} {
            font-size: 1.8rem;
            line-height: 3rem;
            font-weight: 600;
        }

        @media #{$layout-tablet} {
            font-size: 2rem;
            line-height: 3rem;
            font-weight: 600;
        }
    }

    p {
        color: $light-color-body;
    }

    .col-lg-6 {
        float: left;
    }
}

.branding-item {
    text-align: center;
    padding-bottom: 5vh;
    padding-top: 10vh;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media #{$layout-mobile-sm} {
        h2 {
            font-size: 1.2rem;
        }
    }


    img {
        max-width: 700px;
        border-radius: 20px;
        width: 100%
    }
}

.branding-website {
    text-align: center;
    display: inline-block;
    padding-top: 10vh;

    @media #{$layout-mobile-sm} {
        h2 {
            font-size: 1.2rem;
        }
    }

    .col-lg-6 {
        padding-left: 1vw;
        padding-right: 1vw;
        padding-top: 1vh;
        padding-bottom: 1vh;
    }

    img {
        //max-width: 500px;
        border-radius: 20px;
        width: 100%;
    }

}

.closeButton {
    position: sticky;
    border: none;
    top: 0px;
    /* right: -50px; */
    margin-right: -2vw;
    font-size: 1.4em;
    font-weight: bold;

    @media #{$layout-mobile-sm} {
        margin-right: -6vw;
    }
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.lightbox {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    align-items: center;

    visibility: hidden;
    opacity: 0;

    transition: opacity ease 0.4s;
}

.modal-main::-webkit-scrollbar {
    display: none;
}

.show {
    visibility: visible;
    opacity: 1;
}

.show_image {
    width: 100vw;
    height: 100vh;
    object-fit: contain;
}

.app_tour_gif {
    float: left;
}

.app_tour_text {
    float: left;

    @media #{$layout-mobile-sm} {
        padding-top: 0;
    }

    @media #{$layout-mobile-lg} {
        padding-top: 0;
    }

    @media #{$layout-tablet} {
        padding-top: 0;
    }

}